import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import AxhatSinha from '../../assets/leadership/AxhatSinha.png';
import AnjuSinha from '../../assets/leadership/AnjuSinha.png';
import SanjeevKumar from '../../assets/leadership/SanjeevKumar.png';

const leadershipData = [
    {
        name: "Mr. Sanjeev Kumar",
        position: "CMD & Managing Director",
        description: "Mr. Kumar has been in the field of real estate and civil construction since 1998. He has a vision to build world-class real-estate concepts with the highest standards of professionalism, ethics, and customer service.",
        image: SanjeevKumar, // Replace with actual image path
    },
    {
        name: "Mrs Anju Sinha",
        position: "Executive Director",
        description: "Mrs. Anju Sinha has contributed significantly to building a new India through real estate projects, focusing on quality and customer satisfaction. She plays an integral role in operations and project management.",
        image: AnjuSinha, // Replace with actual image path
    },
    {
        name: "Mr. Axhat Sinha",
        position: "CEO, Bhubaneswar",
        description: "Mr. Axhat Sinha has spearheaded the development of Diamond City, Bhubaneswar. His leadership has led to the transformation of the site into a profitable and sustainable project.",
        image: AxhatSinha, // Replace with actual image path
    },
    {
        name: "Mr. Kshitij",
        position: "Chief Operations Officer",
        description: "Mr. Kshitij, in charge of operations, has been pivotal in developing the group’s sites. His proactive approach has contributed to the company’s growth and continued success.",
        image: "path/to/image4.jpg", // Replace with actual image path
    }
];

const LeadershipCarousel = () => {
    const settings = {
        lazyLoad: true, // Enable lazy load in the slider
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className=" py-8 lg:py-12 px-4 lg:px-12 bg-marble lg:border-2 border-champagne lg:rounded-2xl lg:mx-32 shadow-lg h-[550px] lg:h-[630px]">
            <h2 className="text-left text-2xl lg:text-4xl font-bold mb-4 text-onyx">Leadership</h2>
            <Slider {...settings}>
                {leadershipData.map((leader, index) => (
                    <div key={index} className="p-4 -ml-32">
                        <div className="bg-onyx p-6 rounded-2xl shadow-lg text-center hover:shadow-2xl transition-shadow duration-300">
                            <img src={leader.image} alt={leader.name} className="w-32 h-32 mx-auto rounded-full" loading="lazy" />
                            <h3 className="text-lg lg:text-xl font-semibold text-pearl">{leader.name}</h3>
                            <h4 className="text-champagne font-medium">{leader.position}</h4>
                            <p className="mt-4 text-sm lg:text-base text-pearl">{leader.description}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default LeadershipCarousel;
