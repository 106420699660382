import React from "react";
import { FaTwitter, FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";

const pagelinks = [
    {
        title: 'Quick Links',
        links: [
            { title: 'Home', url: '/' },
            { title: 'About Us', url: '/aboutus' },
            { title: 'Accolades', url: '/aboutus' },
            { title: 'Contact Us', url: '/contactus' },
        ]
    },
    {
        title: 'Projects',
        links: [
            { title: 'Diamond City, Bhubaneswar', url: '/diamondcitycuttackbhubaneswar' },
            { title: 'Diamond City, Ranchi', url: '/diamondcityranchi' },
            { title: 'Satellite City, Muzzaffarpur', url: '/satellitecitymuzaffarpur' },
            { title: 'Techno City, Patna', url: '/technocitypatna' },
        ]
    },
];

const officeLocations = [
    {
        title: 'Registered Office',
        address: 'House No.2, Mihir Path, East Boring Canal Road - Pincode: 800001, Patna, Bihar (India)',
        phone1: '08010133921',
        phone2: '0612-3215586'
    },
    {
        title: 'Corporate Office',
        address: '403, Gupta Tower, Radium Road, Kutchery Chowk - Pincode: 834001, Ranchi, Jharkhand (India)',
        phone1: '07759994411',
        phone2: '0651-2361622'
    },
    {
        title: 'Bhubaneswar Office',
        address: 'Plot No.: 2340, Nuagarh, Telengapentha, NH-16, Highway Cuttack - Pincode: 754001, Orissa (India)',
        phone1: '06299056530',
        phone2: '0651-2361622'
    },
];

function BottomBar() {
    return (
        <footer className="bg-pearl py-12 lg:py-16">
            <div className="container mx-auto px-4">
                <div className="flex flex-col lg:flex-row justify-between">
                    {/* Company Information */}
                    <div className="w-full lg:w-1/2 mb-12 lg:mb-0 text-left lg:pr-8">
                        <h2 className="text-onyx text-2xl font-bold mb-4">About Our Company</h2>
                        <p className="text-gray-700 mb-6">
                            We are one of the leading Real Estate Companies of India, established a decade ago to provide superior quality residential and commercial complexes.
                        </p>
                        {/* Social Icons */}
                        <div className="flex space-x-4">
                            {/* <a href="#" className="text-onyx hover:text-champagne transition-colors duration-300"><FaTwitter size={24} /></a> */}
                            <a href="https://www.facebook.com/eecd.in/" className="text-onyx hover:text-champagne transition-colors duration-300"><FaFacebookF size={24} /></a>
                            <a href="https://www.instagram.com/eecd_official/" className="text-onyx hover:text-champagne transition-colors duration-300"><FaInstagram size={24} /></a>
                            <a href="https://www.linkedin.com/company/eastern-estate-construction-&-developers-private-limited/?originalSubdomain=in" className="text-onyx hover:text-champagne transition-colors duration-300"><FaLinkedinIn size={24} /></a>
                            {/* <a href="#" className="text-onyx hover:text-champagne transition-colors duration-300"><FaYoutube size={24} /></a> */}
                        </div>
                    </div>

                    {/* Dynamic Sections */}
                    <div className="w-full flex flex-wrap justify-between text-left lg:px-8">
                        {pagelinks.map((section, index) => (
                            <div key={index} className="w-full sm:w-1/2 lg:w-1/2 mb-6 lg:mb-0">
                                <h3 className="font-bold text-lg text-onyx mb-4">{section.title}</h3>
                                <ul className="space-y-2">
                                    {section.links.map((link, i) => (
                                        <li key={i}><a href={link.url} className="text-gray-700 hover:text-onyx transition-colors duration-200">{link.title}</a></li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>

                    {/* Office Locations */}
                    <div className="w-fit lg:px-8 lg:space-y-4">
                        {officeLocations.map((office, index) => (
                            <div key={index} className="text-left">
                                <h3 className="font-bold text-lg text-onyx">{office.title}</h3>
                                <p className="text-gray-600 text-sm mt-2">
                                    {office.address}<br />
                                    <a href={`tel:+91${office.phone1}`} className="text-champagne hover:underline">{office.phone1}</a><br />
                                    <a href={`tel:+91${office.phone2}`} className="text-champagne hover:underline">{office.phone2}</a>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="container mx-auto text-center text-gray-600 py-4 border-t border-gray-300 mt-8">
                <p>All Rights Reserved. &copy; 2024 Eastern Estate Constructions and Developers</p>
            </div>
        </footer>
    );
}

export default BottomBar;
